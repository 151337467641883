import { CONFIG } from 'constants/config';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { lazy } from 'react';

export const getRootApp = () => {
  switch (CONFIG.mode) {
    case CONFIG.modes.game:
      return lazy(() => import('./Containers/GameApp'));
    case CONFIG.modes.factory:
      return lazy(() => import('./Containers/FactoryApp'));
    case CONFIG.modes.lands:
      return lazy(() => import('./Containers/LandsApp'));
    case CONFIG.modes.bridge:
      return lazy(() => import('./Containers/BridgeApp'));
    case CONFIG.modes.chunk:
      return lazy(() => import('./Containers/ChunkApp'));
    default:
      return lazy(() => import('./Containers/MainApp'));
  }
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DUCKLY_ID,
})(getRootApp());
