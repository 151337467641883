import arbitrumIcon from 'icons/network/arbitrum.png';
import ethereumIcon from 'icons/network/ethereum.png';
import polygonIcon from 'icons/network/polygon.png';
import { Chain } from 'types/Chain';

import { APP_ENV, ENV_TYPES } from './appEnv';

const NETWORKS = {
  [Chain.main]: {
    chainId: Chain.main,
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  [Chain.goerli]: {
    chainId: Chain.goerli,
    chainName: 'Goerli test network',
    nativeCurrency: {
      name: 'GoerliETH',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  [Chain.polygon]: {
    chainId: Chain.polygon,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  [Chain.mumbai]: {
    chainId: Chain.mumbai,
    chainName: 'Mumbai',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    // rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  [Chain.elysium_test]: {
    chainId: Chain.elysium_test,
    chainName: 'ElysiumTest',
    nativeCurrency: {
      name: 'LAVA',
      symbol: 'LAVA',
      decimals: 18,
    },
    rpcUrls: ['https://elysium-test-rpc.vulcanforged.com'],
    blockExplorerUrls: ['https://elysium-explorer.vulcanforged.com'],
  },
  [Chain.elysium]: {
    chainId: Chain.elysium,
    chainName: 'Elysium',
    nativeCurrency: {
      name: 'LAVA',
      symbol: 'LAVA',
      decimals: 18,
    },
    rpcUrls: ['https://elysium-rpc.vulcanforged.com'],
    blockExplorerUrls: ['https://elysiumscan.vulcanforged.com'],
  },
  [Chain.arbitrum]: {
    chainId: Chain.arbitrum,
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/one/public'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  [Chain.arbitrum_test]: {
    chainId: Chain.arbitrum_test,
    chainName: 'Arbitrum Goerli',
    nativeCurrency: {
      name: 'AGOR',
      symbol: 'AGOR',
      decimals: 18,
    },
    rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/goerli/public'],
    blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'],
  },
};

const DEV_CONFIG = {
  domains: {
    main: 'https://main-dev.redeyestudio.io/',
    game: 'https://game-dev.redeyestudio.io/',
    lands: 'https://land-dev.redeyestudio.io/',
    bridge: 'https://bridge-dev.redeyestudio.io/',
  },
  alchemy: {
    name: 'GOB-DEV',
    jsonRpcUrl: 'https://eth-goerli.g.alchemy.com/v2/x_x7D98KTv2Jyy-cFO2qDE03hlo5HlfS',
  },
  contract: {
    ether: {
      network: 'goerli',
      gob: '0x6eB047049ed16baD6d567BB2267404ffF47F8bE1',
      holder: '',
    },
    polygon: {
      network: 'maticmum',
      gob: '0x995abfd579c1db43bc31e77dcce6d526a8cbb335',
      holder: '0x7a504B6E04493BCccB9d7873950bE1f4D91c2263',
    },
    arbitrum: {
      network: 'arbitrum-goerli', // for ethers infura provider...
      gob: '0xD11F10124F41A6F0351b9295d9DC6e23eA0FC434',
      holder: '0xdB6b3E1786f6667A32F43c51B5031a4C6f0ED313',
    },
    imx: {
      packs: '0x75B4834B1Fa00F2dcD223e6ca3A01d655aAb5B4f',
      chainId: Chain.goerli,
    },
    elysium: {
      packs: '0xd8b292a6a573260795f9801a7e7C91dB2a4fAa3C',
      chainId: Chain.elysium_test,
    },
    landMintReceiver: '0x871271468913487646fa5A910c1De23CeaE1F3e0',
    merkle: 'https://claim-rewards-dev.s3.amazonaws.com/gobRewards/merkleData.json',
  },
  chian: {
    main: Chain.goerli,
    polygon: Chain.mumbai,
    arbitrum: Chain.arbitrum_test,
    elysium: Chain.elysium_test,
  },
  game: {
    bucket: 'https://d31sk0d42o7nly.cloudfront.net/testsite/webglbuild',
    versionUrl: '',
    versioning: false,
  },
  servers: {
    claim: 'https://gob-claim-dev.herokuapp.com',
    main: 'https://main-api-dev.herokuapp.com',
    game: 'https://dev-api.goonsofbalatroon.com/api',
    store: 'https://store-test.getagoon.com',
    rewards: 'https://gob-rewards-dev.herokuapp.com',
    gitex: 'https://gitex-api.goonsofbalatroon.com',
    imxLink: 'https://link.sandbox.x.immutable.com',
    imxApi: 'https://api.sandbox.x.immutable.com/v1',
    metadata: 'https://goons-metadata-dev.herokuapp.com',
    metabod: 'https://goonbodies.s3.amazonaws.com',
  },
  market: {
    networks: [
      {
        chainId: Chain.goerli,
        label: 'Goerli',
      },
      {
        chainId: Chain.arbitrum_test,
        label: 'Arbitrum',
      },
    ],
    packPurchase: {
      eth: '0xd2C72392F51B85067635416f8ac04e758366A18E',
      gob: '0x6a0D46a19F8b5E67d34819495935AA24c333F759',
      pGob: '0x1dCF1C9e9071B7183534D1E399853E8068127C03',
    },
    imxPackCollection: 'https://market.sandbox.immutable.com/collections/0x75B4834B1Fa00F2dcD223e6ca3A01d655aAb5B4f',
  },
  networkSwitch: [
    {
      chainId: Chain.goerli,
      label: 'Goerli',
      icon: ethereumIcon,
    },
    // {
    //   chainId: Chain.elysium_test,
    //   label: 'Elysium',
    //   icon: elysiumIcon,
    // },
    {
      chainId: Chain.arbitrum_test,
      label: 'Arbitrum (Test)',
      icon: arbitrumIcon,
    },
    {
      chainId: Chain.mumbai,
      label: 'Mumbai',
      icon: polygonIcon,
    },
  ],
  tokenKey: '_gob_dev_auth_token_',
  gameTokenKey: '_gob_dev_game_auth_token_',
  refreshTokenKey: '_gob_dev_auth_refresh_token_',
  clanNameKey: '_gob_dev_clan_name_key_',
};

const ENV_CONFIGS: Record<ENV_TYPES, typeof DEV_CONFIG> = {
  [ENV_TYPES.development]: DEV_CONFIG,
  [ENV_TYPES.staging]: {
    domains: {
      main: 'https://main-staging.redeyestudio.io/',
      game: 'https://game-staging.redeyestudio.io/',
      lands: 'https://land-staging.redeyestudio.io/',
      bridge: 'https://bridge.goonsofbalatroon.com/',
    },
    alchemy: {
      name: 'Goons of Balatroon',
      jsonRpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/j4G9D3UJyY7XYwl4eOKZlfgo1V3_JIHO',
    },
    contract: {
      ether: {
        network: 'homestead',
        gob: '0xd6929179d752d5d25c5efe2d9729eb77d7138a80',
        holder: '',
      },
      polygon: {
        network: 'matic',
        gob: '0x16d63619Cd67B15Ff822bFeB60388a226D2e452b',
        holder: '0x392cc812E06E86f3F96cd1CFD390dfb490A4A8Db',
      },
      arbitrum: {
        network: 'arbitrum', // for ethers infura provider...
        gob: '0xd5a56b510247db33695b0bea29992ac6670081ea',
        holder: '0x039947a5d9031226d1c5926c2343d9543e031424',
      },
      imx: {
        packs: '0x7cafac1a8a0d316449c944b0d6cb71328646f3c0',
        chainId: Chain.main,
      },
      elysium: {
        packs: '',
        chainId: Chain.elysium,
      },
      landMintReceiver: '',
      merkle: 'https://claim-rewards-prod.s3.amazonaws.com/gobRewards/merkleData.json',
    },
    chian: {
      main: Chain.main,
      polygon: Chain.polygon,
      arbitrum: Chain.arbitrum,
      elysium: Chain.elysium,
    },
    game: {
      bucket: 'https://d1c75srlrjas3f.cloudfront.net',
      versionUrl: 'https://goons-game-staging.s3.amazonaws.com/currentGameClientVersion.json',
      versioning: true,
    },
    servers: {
      claim: 'https://gob-claim-prod.herokuapp.com',
      main: 'https://api-stag.balatroon.io',
      game: 'https://staging-api.goonsofbalatroon.com/api',
      store: 'https://store-api.goonsofbalatroon.com',
      rewards: 'https://gob-rewards-stag.herokuapp.com',
      gitex: 'https://gitex-api.goonsofbalatroon.com',
      imxLink: 'https://link.x.immutable.com',
      imxApi: 'https://api.x.immutable.com/v1',
      metadata: 'https://goons-metadata.herokuapp.com',
      metabod: 'https://goonbodies.s3.amazonaws.com',
    },
    market: {
      networks: [
        {
          chainId: Chain.main,
          label: 'Ethereum',
        },
        {
          chainId: Chain.arbitrum,
          label: 'Arbitrum',
        },
      ],
      packPurchase: {
        eth: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
        gob: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
        pGob: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
      },
      imxPackCollection: 'https://immutascan.io/address/0x7cafac1a8a0d316449c944b0d6cb71328646f3c0',
    },
    networkSwitch: [
      {
        chainId: Chain.main,
        label: 'Ethereum',
        icon: ethereumIcon,
      },
      // {
      //   chainId: Chain.elysium,
      //   label: 'Elysium',
      //   icon: elysiumIcon,
      // },
      {
        chainId: Chain.arbitrum,
        label: 'Arbitrum',
        icon: arbitrumIcon,
      },
      {
        chainId: Chain.polygon,
        label: 'Polygon',
        icon: polygonIcon,
      },
    ],
    tokenKey: '_gob_staging_auth_token_',
    gameTokenKey: '_gob_staging_game_auth_token_',
    refreshTokenKey: '_gob_staging_auth_refresh_token_',
    clanNameKey: '_gob_staging_clan_name_key_',
  },
  [ENV_TYPES.production]: {
    domains: {
      main: 'https://goonsofbalatroon.com/',
      game: 'https://play.goonsofbalatroon.com/',
      lands: 'https://balatroon.world/',
      bridge: 'https://bridge.goonsofbalatroon.com/',
    },
    alchemy: {
      name: 'Goons of Balatroon',
      jsonRpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/j4G9D3UJyY7XYwl4eOKZlfgo1V3_JIHO',
    },
    contract: {
      ether: {
        network: 'homestead',
        gob: '0xd6929179d752d5d25c5efe2d9729eb77d7138a80',
        holder: '',
      },
      polygon: {
        network: 'matic',
        gob: '0x16d63619Cd67B15Ff822bFeB60388a226D2e452b',
        holder: '0x392cc812E06E86f3F96cd1CFD390dfb490A4A8Db',
      },
      arbitrum: {
        network: 'arbitrum', // for ethers infura provider...
        gob: '0xd5a56b510247db33695b0bea29992ac6670081ea',
        holder: '0x039947a5d9031226d1c5926c2343d9543e031424',
      },
      imx: {
        packs: '0x7cafac1a8a0d316449c944b0d6cb71328646f3c0',
        chainId: Chain.main,
      },
      elysium: {
        packs: '',
        chainId: Chain.elysium,
      },
      landMintReceiver: '',
      merkle: 'https://claim-rewards-prod.s3.amazonaws.com/gobRewards/merkleData.json',
    },
    chian: {
      main: Chain.main,
      polygon: Chain.polygon,
      arbitrum: Chain.arbitrum,
      elysium: Chain.elysium,
    },
    game: {
      bucket: 'https://d1i5m0lh8k9exh.cloudfront.net',
      versionUrl: 'https://goons-game-prod.s3.amazonaws.com/currentGameClientVersion.json',
      versioning: true,
    },
    servers: {
      claim: 'https://gob-claim-prod.herokuapp.com',
      main: 'https://api.balatroon.io',
      game: 'https://api.goonsofbalatroon.com/api',
      store: 'https://store-api.goonsofbalatroon.com',
      rewards: 'https://gob-rewards-prod.herokuapp.com',
      gitex: 'https://gitex-api.goonsofbalatroon.com',
      imxLink: 'https://link.x.immutable.com',
      imxApi: 'https://api.x.immutable.com/v1',
      metadata: 'https://goons-metadata.herokuapp.com',
      metabod: 'https://goonbodies.s3.amazonaws.com',
    },
    market: {
      networks: [
        {
          chainId: Chain.main,
          label: 'Ethereum',
        },
        {
          chainId: Chain.arbitrum,
          label: 'Arbitrum',
        },
      ],
      packPurchase: {
        eth: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
        gob: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
        pGob: '0x59F70F2127dD795e26Af7dF34835d0264B20BC5d',
      },
      imxPackCollection: 'https://immutascan.io/address/0x7cafac1a8a0d316449c944b0d6cb71328646f3c0',
    },
    networkSwitch: [
      {
        chainId: Chain.main,
        label: 'Ethereum',
        icon: ethereumIcon,
      },
      // {
      //   chainId: Chain.elysium,
      //   label: 'Elysium',
      //   icon: elysiumIcon,
      // },
      {
        chainId: Chain.arbitrum,
        label: 'Arbitrum',
        icon: arbitrumIcon,
      },
      {
        chainId: Chain.polygon,
        label: 'Polygon',
        icon: polygonIcon,
      },
    ],
    tokenKey: '_gob_prod_auth_token_',
    gameTokenKey: '_gob_prod_game_auth_token_',
    refreshTokenKey: '_gob_prod_auth_refresh_token_',
    clanNameKey: '_gob_prod_clan_name_key_',
  },
};

export const CONFIG = {
  ...ENV_CONFIGS[APP_ENV],
  mode: process.env.REACT_APP_NAME ?? '',
  modes: {
    game: 'game',
    factory: 'factory',
    main: 'main',
    lands: 'lands',
    bridge: 'bridge',
    chunk: 'chunk',
  },
  urls: {
    openSea: {
      goons: 'https://opensea.io/collection/goonsofbalatroon',
      bods: 'https://opensea.io/collection/goonbods',
      accountActivity: 'https://opensea.io/account?tab=activity',
      landNFT: 'https://opensea.io/assets/ethereum/0x872a7537861e74b892b52246f017703872c46602',
    },
    buyGob: {
      default: 'https://app.uniswap.org/#/swap?outputCurrency=0xd6929179d752d5d25c5efe2d9729eb77d7138a80&chain=mainnet',
      gate: 'https://www.gate.io/trade/GOB_USDT',
      uniSwap: 'https://app.uniswap.org/#/swap?outputCurrency=0xd6929179d752d5d25c5efe2d9729eb77d7138a80&chain=mainnet',
      quickSwap: 'https://app.sushi.com/swap?outputCurrency=0xD5A56b510247DB33695b0bEa29992AC6670081ea&chainId=42161',
    },
    pool: {
      public: 'https://stake.unifyre.io/gobpoly/info/0xed3c013e98824b22cae11d5126b2af6333bc7b05/POLYGON',
      land1: 'https://stake.unifyre.io/gobpoly/info/0xfa366da4f3822408733366e9cfa285f1ac6e5fca/POLYGON',
      land5: 'https://stake.unifyre.io/gobpoly/info/0xcc7e700040e57f45975641cc2c47fb7d1e736d30/POLYGON',
      land20: 'https://stake.unifyre.io/gobpoly/info/0x9242169af50c95084109984080249f6717a2f62f/POLYGON',
      landmark: 'https://stake.unifyre.io/gobpoly/info/0xa630fc6c0ce19caed5ae245f88b1693ee8ee0733/POLYGON',
      stake: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9033866812564',
    },
    chunk: {
      twitter: 'http://www.twitter.com/chunks_token',
      telegram: 'https://t.me/chunks_token',
      discord: 'https://discord.gg/goonsnft',
      utility: 'https://docs.goonsofbalatroon.com/ecosystem/usdchunks-token',
      tokenomic: 'https://docs.goonsofbalatroon.com/ecosystem/usdchunks-token/tokenomics',
      tax: 'https://docs.goonsofbalatroon.com/ecosystem/usdchunks-token/tax-system',
      sale: 'https://docs.goonsofbalatroon.com/ecosystem/usdchunks-token/fair-sale-offering',
      lore: 'https://mirror.xyz/chunkswtf.eth/H9VDS4JQ7gXMuLriWFFBBhnEemYSZ_LqGolhNa2eVSQ',
    },
    discord: 'https://discord.com/invite/goonsnft',
    discordForLand: 'https://discord.com/invite/Vyv54QYkVb',
    discordForMaintenance: 'https://discord.gg/invite/goonsnft',
    medium: 'https://medium.com/@GoonsofBalatroon/how-to-earn-gob-tokens-from-nfts-aba53264a9c5',
    telegram: 'https://t.me/goonsnft',
    instagram: 'https://instagram.com/goonsnft/',
    gobFactory: 'https://factory.goonsofbalatroon.com/',
    giveawayFaq: {
      default: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9090385958036',
      1: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9142762229908-Giveaway-1-Prize-Pool-Winners',
      2: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9142807466644-Giveaway-2-Prize-Pool-Winners',
      3: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9142845936020-Giveaway-3-Prize-Pool-Winners',
      4: 'https://help.goonsofbalatroon.com/hc/en-us/articles/9142849528852-Giveaway-4-Prize-Pool-Winners',
    },
    twitter: process.env.REACT_APP_NAME === 'lands' ? 'https://twitter.com/balatroon' : 'https://twitter.com/goonsnft',
    landWebSite: 'https://balatroon.world/',
    documentation: 'https://docs.goonsofbalatroon.com/',
    contracts: 'https://docs.goonsofbalatroon.com/important/contracts',
    tokenomics: 'https://docs.goonsofbalatroon.com/ecosystem/usdgob-token/tokenomics',
    audit: 'https://docs.goonsofbalatroon.com/important/audit',
    gobstrategy:
      'https://docs.google.com/presentation/d/e/2PACX-1vTsIDi-GjFlCg9NlEEnLQtFdoqPDFssPFgW7Xn0nrrR5nR-C8o-T0EPu-leGfzIdC6Osuz_cN-jf8ur/pub?start=false&loop=false&delayms=3000&slide=id.g2014e583791_7_510',
    klaviyo: 'https://cors-global.herokuapp.com/https://a.klaviyo.com/api/v2',
    defaultAvatar: 'https://gobstaticfiles.s3.amazonaws.com/AvatarPlaceholders/placeholder_bull_3.png',
    gForm: {
      contact:
        'https://docs.google.com/forms/d/e/1FAIpQLSemlGdIWEZfE7-4QxwQ5Uqq8ivbwDX98P02uK7rwiBgVFs4qQ/viewform?embedded=true',
      bugReport:
        'https://docs.google.com/forms/d/e/1FAIpQLSfPbWU-fCRPeIHYwtkFyWxu2PFyG38KMMMMju53uQpyLGiC2A/viewform?embedded=true',
    },
  },
  storage: {
    redirect: 'GOB:REDIRECT:PAGE:URL',
    referralRefId: 'GOB:REFERRAL:REF:ID',
    notification: 'GOB:NOTIFICATION:SERVICE',
    openPackIntro: 'GOB:OPEN:PACK:INTRO:MODAL',
  },
  keys: {
    recaptcha: process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '',
    klaviyoKey: process.env.REACT_APP_KLAVIYO_PRIVATE_KEY ?? '',
    klaviyoListId: process.env.REACT_APP_KLAVIYO_LIST_ID ?? '',
    tagManager: process.env.REACT_APP_TAG_MANAGER_ID ?? '',
  },
  networks: NETWORKS,
  gobUsdRate: 0.05,
};

export const GOB_TOKEN_TRAITS = {
  decimals: 18,
  symbol: 'GOB',
  logo: 'http://etherscan.io/token/images/goonsofbalatroon_32.png',
};

export const POLYGON_GOB_TOKEN = {
  addr: CONFIG.contract.polygon.gob || '',
  decimals: 18,
  symbol: 'GOB',
  logo: 'http://etherscan.io/token/images/goonsofbalatroon_32.png',
};

export const ETHEREUM_GOB_TOKEN = {
  addr: CONFIG.contract.ether.gob || '',
  decimals: 18,
  symbol: 'GOB',
  logo: 'http://etherscan.io/token/images/goonsofbalatroon_32.png',
};

export const ARB_GOB_TOKEN = {
  addr: CONFIG.contract.arbitrum.gob || '',
  decimals: 18,
  symbol: 'GOB',
  logo: 'http://etherscan.io/token/images/goonsofbalatroon_32.png',
};

export const swapItems = [
  {
    label: 'Gate.io',
    link: CONFIG.urls.buyGob.gate,
    external: true,
  },
  {
    label: 'Uniswap (Ethereum)',
    link: CONFIG.urls.buyGob.uniSwap,
    external: true,
  },
  {
    label: 'SushiSwap (Arbitrum)',
    link: CONFIG.urls.buyGob.quickSwap,
    external: true,
  },
];
