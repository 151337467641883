import { css } from 'styled-components';

import type { ScreenSize } from './mediaQueries';

import { MediaQueries } from './mediaQueries';
import { Spacing } from './spacing';

const ColorsMap = {
  gray0: '#1D1B21',
  gray: '#221F27',
  gray10: '#36343C',
  gray30: '#5E5D67',
  gray50: '#868593',
  gray70: '#AFAEBE',
  gray100: '#EBECFF',
  beige10: '#382F31',
  beige100: '#FABF88',
  green10: '#1D392D',
  green50: '#0A9845',
  green100: '#00CD52',
  red: '#BD4448',
  red10: '#44242D',
  red100: '#FF3156',
  white50: 'rgb(235 236 255 / 50%)',
  yellow: '#FFA800',
  yellow50: '#FEB700',
  yellow80: '#FFAF12',
  blue: '#16bbff',
  blue50: '#3794EB',
  black: '#000',
  white: '#fff',
};

const ShadowMap = {
  beige: css`
    box-shadow: 0px 0px 10px rgba(250, 191, 136, 0.3);
  `,
  green: css`
    box-shadow: 0px 0px 10px rgba(0, 205, 82, 0.3);
  `,
  gray: css`
    box-shadow: 0px 0px 10px rgba(91, 87, 96, 0.3);
  `,
  darkGray: css`
    box-shadow: 0px 0px 10px rgba(52, 49, 58, 0.3);
  `,
  red: css`
    box-shadow: 0 0 0 2px rgb(189 68 72 / 20%);
  `,
  white: css`
    box-shadow: 0 0 0 2px rgb(250 250 250 / 20%);
  `,
};

const TypographyMap = {
  h1240Black: css`
    font-family: Rubik;
    font-weight: 900;
    font-size: 240px;
    line-height: 120%;
  `,
  h190Black: css`
    font-family: Rubik;
    font-weight: 900;
    font-size: 70px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  `,
  h170Bold: css`
    font-family: Paytone One;
    font-weight: normal;
    font-size: 70px;
    line-height: 120%;
  `,
  h160Bold: css`
    font-family: Paytone One;
    font-weight: 400;
    font-size: 60px;
    line-height: 120%;

    @media only screen and (max-width: 640px) {
      font-size: 32px;
    }
  `,
  h164Regular: css`
    font-family: Paytone One;
    font-weight: normal;
    font-size: 64px;
    line-height: 120%;
  `,
  h156Bold: css`
    font-family: Rubik;
    font-weight: 900;
    font-size: 56px;
    line-height: 120%;
  `,
  h156Regular: css`
    font-family: Paytone One;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
  `,
  h148Regular: css`
    font-family: Paytone One;
    font-weight: 400;
    font-size: 48px;
    line-height: 120%;
  `,
  h140Regular: css`
    font-family: Paytone One;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
  `,
  h240Black: css`
    font-family: Rubik;
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
  `,
  h232Black: css`
    font-family: Rubik;
    font-weight: 900;
    font-size: 32px;
    line-height: 120%;
    text-transform: uppercase;
  `,
  h232Extra: css`
    font-family: Rubik;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
  `,
  h324Extra: css`
    font-family: Roboto;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
  `,
  h320Extra: css`
    font-family: Roboto;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
  `,
  h320Regular: css`
    font-family: Paytone One;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
  `,
  b122Regular: css`
    font-family: Roboto;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
  `,
  b118Bold: css`
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  `,
  b118Regular: css`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
  `,
  b216Regular: css`
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.5px;
  `,
  b216Bold: css`
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
  `,
  b214Regular: css`
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.5px;
  `,
  b312Regular: css`
    font-family: Roboto;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  `,
  b312Bold: css`
    font-family: Roboto;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  `,
};

const unit = 10;

type Theme = {
  colors: typeof ColorsMap;
  typography: typeof TypographyMap;
  shadow: typeof ShadowMap;
  spacing: typeof Spacing;
  headerHeight: number;
  gap(n: number): string;
  between: (size1: ScreenSize, size2: ScreenSize) => string;
  only: (size: ScreenSize) => string;
  down: (size: ScreenSize) => string;
  up: (size: ScreenSize) => string;
};

export const theme: Theme = {
  colors: ColorsMap,
  typography: TypographyMap,
  shadow: ShadowMap,
  spacing: Spacing,
  headerHeight: 88,
  gap: (n) => `${n * unit}px`,
  between: MediaQueries.between,
  down: MediaQueries.down,
  only: MediaQueries.only,
  up: MediaQueries.up,
};

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
